<!--  -->
<template>
  <div class="content-wrapper">
    <div ref="scrollDiv" class="warpper">
      <div v-for="(item, index) in dialog" :key="index" class="dialog-item">
        <div class="choose" v-if="item.ansText">
          <div class="ask">{{ item.ansText }}</div>
          <img src="@/assets/header.png" class="avatar" />
        </div>
        <div class="computer">
          <img src="@/assets/logo.png" class="avatar" />

          <div class="right">
            <div v-if="item.ask" class="ask">{{ item.ask }}</div>
            <div class="ansbox" v-if="item.children">
              <div
                v-for="ansItem in item.children"
                :key="ansItem.code"
                @click="askAbout(ansItem, item)"
                class="ans"
              >
                {{ ansItem.ans }}
              </div>
            </div>
            <div class="value" v-if="item.value">
              {{ item.value }}
            </div>
            <div class="formValue" v-if="item.formKey">
              可办理政策
              <div class="goOnline" @click="goOnline(item.formKey)">
                {{ item.formValue }}&nbsp;点击办理
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom" v-if="dialog.length > 1">
        <span @click="goback()">返回上一级</span>
      </div>
    </div>
  </div>
</template>

<script>
import tree from "./config.js";
import { queryItemByKey } from "@/api/policy/item";
import { Toast } from "vant";
export default {
  data() {
    return {
      code: "1",
      count: 1,
      dialog: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.searchCode(tree);
  },

  methods: {
    askAbout(ansItem, item) {
      this.code = ansItem.code;
      if (item.clickAble) {
        let lenght = this.dialog.length;
        this.dialog[lenght - 1].clickAble = 0;
        this.searchCode(tree);
        this.dialog[lenght].ansText = ansItem.ans;
      }
    },

    searchCode(arr) {
      for (let i in arr) {
        // if (arr[i].formKey) {
        //   this.handleScrollBottom();
        // }
        if (arr[i].code == this.code) {
          let result = {
            ask: arr[i].ask,
            value: arr[i].value,
            clickAble: 1,
            formKey: arr[i].formKey,
            formValue: arr[i].formValue,
          };
          if (arr[i].children) {
            result.children = arr[i].children.map((item) => {
              return {
                ans: item.ans,
                code: item.code,
              };
            });
          }
          this.dialog.push(result);
          return;
        }
        if (arr[i].children) {
          this.searchCode(arr[i].children);
        }
      }
    },
    // 滚动到底部
    // handleScrollBottom() {
    //   this.$nextTick(() => {
    //     this.$refs.scrollDiv.scrollIntoView({
    //       behavior: "smooth",
    //       block: "end",
    //     });
    //   });
    // },
    goback() {
      this.dialog.pop();
      let lenght = this.dialog.length;
      this.dialog[lenght - 1].clickAble = 1;
    },
    goOnline(formKey) {
      queryItemByKey({
        formKey,
      }).then((res) => {
        let { data } = res;
        if (res.data.itemStatus == "1") {
          let itemId = res.data.itemId;
          this.$router.push({
            path: `/apply/${itemId}`,
          });
        } else {
          Toast(
            "当前办事项已暂停办理，恢复办理时间和相关事宜可咨询业务受理部门。给您带来的不便，敬请谅解！"
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  background: #ccc;
  // padding: 1.25rem;
  min-height: 100vh;
  padding: 0.5rem 0.25rem;
  overflow: hidden;
  .warpper {
    overflow: auto;
    height: 100%;
    .dialog-item {
      .choose {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0.25rem;
        .ask {
          background: #b6ccfe;
          padding: 0.25rem;
          display: inline-block;
          border-radius: 0.2rem;
          margin-right: 0.25rem;
        }

        .avatar {
          width: 0.88rem;
          height: 0.88rem;
          margin-right: 0.25rem;
        }
      }
      .computer {
        display: flex;

        .avatar {
          width: 0.88rem;
          height: 0.88rem;
          margin-right: 0.25rem;
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0.625rem;
          .ask {
            background: #b6ccfe;
            padding: 0.3rem;
            display: inline-block;
            border-radius: 0.2rem;
            margin-bottom: 0.5rem;
            max-width: 6rem;
          }
          .ansbox {
            background: #f6f6f6;
            display: inline-block;
            border-radius: 0.2rem;
            margin-bottom: 0.25rem;
            .ans {
              cursor: pointer;
              padding: 0.3rem;
              width: 4.5rem;
              border-bottom: 0.0625rem solid #e5e5e5;
            }
            :last-child.ans {
              border-bottom: none;
            }
          }
          .value {
            background: #b6ccfe;
            padding: 0.25rem;
          }

          .formValue {
            display: flex;
            background: #b6ccfe;
            padding: 0.25rem;
            .goOnline {
              margin-left: 0.05rem;
              border-bottom: 0.0625rem solid blue;
              color: blue;
              cursor: pointer;
              flex: 1;
            }
          }
        }
      }
    }
  }
}
.bottom {
  position: fixed;
  z-index: 1;
  overflow: hidden;
  // width: 0.25rem;
  bottom: 2rem;
  right: 0rem;
  color: #e0933a;
  background: #fcebd0;
  font-size: 0.24rem;
  // height: 1rem;
  // line-height: 2.25rem;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.15rem 0rem;
  border-radius: 1rem 0rem 0rem 1rem;
  width: 1.5rem;
}
</style>
