import request from "@/utils/request";
/* 
分页查询办事项 */
function queryPageItem(data) {
  return request({
    url: "/policy/item/queryPageItem",
    method: "POST",
    data,
  });
}
/* 
首页分页查询办事项 
*/
function queryPageItemHome(data) {
  return request({
    url: "/policy/item/queryPageItemHome",
    method: "POST",
    data,
  });
}
/* 新增办事项 */
function addItem(data) {
  return request({
    url: "/policy/item/addItem",
    method: "POST",
    data,
  });
}
/* 查询办事项详情 */
function getItemInfo(params) {
  return request({
    url: "/policy/item/getItemInfo",
    method: "GET",
    params,
  });
}
/* 获取实名认证信息 */
function getApplyForms(params) {
  return request({
    url: "/policy/item/getFormDetail",
    method: "get",
    params,
  });
}
/* 获取办事项 */
function queryItemByKey(params) {
  return request({
    url: "/policy/item/queryItemByKey",
    method: "get",
    params,
  });
}
export {
  queryPageItem,
  queryPageItemHome,
  addItem,
  getItemInfo,
  getApplyForms,
  queryItemByKey,
};
