export default [
  {
    code: "1",
    ask: "请问您毕业日期是否2017年1月1日后",
    children: [
      {
        ans: "否",
        code: "11",
        value: "抱歉您不符合申报当前政策的条件",
      },
      {
        ans: "是",
        code: "12",
        ask: "请问您购房地址是否在航空港区，且购房日期在2017年1月1日之后，且已缴纳房屋契税并取得完税凭证？",
        children: [
          {
            ans: "否",
            code: "121",
            value: "抱歉您不符合申报当前政策的条件",
          },
          {
            ans: "是",
            code: "122",
            ask: "请问您是否为郑州户籍",
            children: [
              {
                ans: "非郑州户籍",
                code: "1221",
                formValue: "航空港区青年人才首次购房补贴",
                formKey: "young_house_subsidy",
              },
              {
                ans: "郑州户籍",
                code: "1222",
                ask: "请问您申请补贴的房屋在谁名下？",
                children: [
                  {
                    ans: "未成年子女",
                    code: "12221",
                    formValue: "航空港区青年人才首次购房补贴",
                    formKey: "young_house_subsidy",
                  },
                  {
                    ans: "本人或配偶",
                    code: "12222",
                    ask: "请问您的学历是？",
                    children: [
                      {
                        ans: "非全日制博士、非全日制硕士",
                        code: "122221",
                        formValue: "航空港区青年人才首次购房补贴",
                        formKey: "young_house_subsidy",
                      },
                      {
                        ans: "全日制本科",
                        ask: "请问您毕业日期是？",
                        code: "122222",
                        children: [
                          {
                            ans: "2022年3月1日（含）-2022年4月14日",
                            code: "1222221",
                            ask: "请问您是否为“双一流”本科高校毕业",
                            children: [
                              {
                                ans: "否",
                                code: "12222211",
                                formValue: "航空港区青年人才首次购房补贴",
                                formKey: "young_house_subsidy",
                              },
                              {
                                ans: "是",
                                code: "12222212",
                                formValue:
                                  "郑州青年人才首次购房补贴（2017年后毕业生）",
                                formKey: "young_house_subsidy_zz2017",
                              },
                            ],
                          },

                          {
                            ans: "2022年4月14日（含）之后",
                            code: "1222222",

                            formValue:
                              "郑州青年人才首次购房补贴（2022年后毕业生）",
                            formKey: "young_house_subsidy_zz2022",
                          },
                          {
                            ans: "2022年3月1日之前",
                            code: "1222223",
                            formValue:
                              "郑州青年人才首次购房补贴（2017年后毕业生）",
                            formKey: "young_house_subsidy_zz2017",
                          },
                        ],
                      },
                      {
                        ans: "全日制博士、全日制硕士、全日制专科、技师（预备技师）",
                        code: "122223",
                        ask: "请问您毕业日期是？",
                        children: [
                          {
                            ans: "2022年4月14日（含）之后",
                            code: "1222231",
                            formValue:
                              "郑州青年人才首次购房补贴（2022年后毕业生）",
                            formKey: "young_house_subsidy_zz2022",
                          },
                          {
                            ans: "2022年4月14日之前",
                            code: "1222232",
                            formValue:
                              "郑州青年人才首次购房补贴（2017年后毕业生）",
                            formKey: "young_house_subsidy_zz2017",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
